import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from '../../components/ghost'
import { MetaData } from '../../components/common/meta'
import { createGlobalStyle } from 'styled-components'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, pageContext, context  }) => {
    const post = data.ghostPost
    // console.log(`---pageContext: ${pageContext}`)
    // console.log(pageContext)
    // console.log(context)
    const GlobalStyle = createGlobalStyle`
    ${pageContext.styleIndex}
    `
    return (
            <>
                <MetaData
                    data={data}
                    location={location}
                    type="article"
                />
                <Layout>
                    <div className="container">
                        <article className="content">
                            { post.localImage &&
                                post.localImage.childImageSharp &&
                                post.localImage.childImageSharp.fluid && (
                                <figure className="post-feature-image">
                                    {/* <img src={ post.feature_image } alt={ post.title } /> */}
                                    <Img
                                        alt={`${post.title} cover image`}
                                        style={{ 'height': '500px', 'margin-bottom': '30px', }}
                                        fluid={post.localImage.childImageSharp.fluid}
                                    />
                                </figure>)}
                            <section className="post-full-content">
                                <h1 className="content-title">{post.title}</h1>

                                {/* The main post content */ }
                                <section
                                    className="content-body load-external-scripts"
                                    dangerouslySetInnerHTML={{ __html: post.html }}
                                />
                            </section>
                        </article>
                    </div>
                </Layout>
                <GlobalStyle />
            </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            localImage {
                childImageSharp {
                  fluid(maxWidth: 3720) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
            ...GhostPostFields
        }
    }
`
